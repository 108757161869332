import * as React from "react"
import { Link, graphql } from "gatsby"
import Yogaback from "../images/yoga1.jpg";



const Blogloop = ({ getdata }) => {
  const posts = getdata.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
    <>
      <p>
      No blog posts found. 
      </p>
    </>
    )
  }

  return (
<>
   <div className="container">
     <div className="columns is-marginless is-multiline is-mobile">
   
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const feato = post.frontmatter.featuredimage
          const featsrc = feato.src.childImageSharp
          return (

<div className="column is-one-third-desktop is-full-mobile" key={title}>
<Link to={post.fields.slug} itemProp="url" >
 {console.log('thizzo: '+featsrc)}

<div className="card" key={post.fields.slug}>
  <div className="card-image">
    <figure className="image is-4by3 backhammer" style={{  
      backgroundImage: "url(" + featsrc.fluid.src + ")",
    }}>
      <img className="picalter" src={featsrc.fluid.src} alt={'Reform Pilates Cork '+title}/>
    
    </figure>
  </div>
  <div className="card-content">
    <div className="media blockonmobile">
      <div className="media-left">
        <figure className="image is-48x48 sallyprofile">
          <img src="https://bulma.io/images/placeholders/96x96.png" alt="Sally @ Reform Pilates Cork" />
        </figure>
      </div>
      <div className="media-content">
        <p className="title is-4">{title}</p>
        <p className="subtitle is-6">@Reform Pilates</p>
      </div>
    </div>
    <div className="content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />

    </div>
  </div>
</div>
</Link>
</div>

          )
        })}
</div>
</div>
</>
  )
}

export default Blogloop

