/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import styled from "styled-components";

const Sectionaholder = styled.div`
.datebuttons{
  padding: 6rem 0;
}

  .holds_datebutton{
    display: flex;
    flex-wrap: wrap;
        justify-content: center
  }

.date_button{
  background: #feef00;
  padding: 1rem 2rem;
  color: #000;
  border-radius: 1rem;
  
         @media screen and (min-width:992px) {
  width: calc(32% - 1rem);                
               }
               @media screen and (min-width: 768px) and (max-width:991px) {
             width: calc(48% - 1rem);    
               }
                 @media screen and (max-width:967px) {
             width: 100%;     
               }
  

  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  border: 1px solid #444;
  text-align: center;
      align-items: center;
    justify-content: center;
    font-size: calc(.8rem + 0.1vw);
  
}

.date_button:hover{
  background: #000;
  color: #feef00;
  border: 1px solid #444;
}

.heado{
  font-size: calc(1.75rem + 0.1vw);
}
.linklister{
  li{
    margin-bottom: 0.5rem;
  }
}
h4{
  margin-bottom: 1.25rem;
  margin-top: 2rem;
}
`;
const Datebuttons = () => {

  return (
    <Sectionaholder>
    <div className="datebuttons">
      <div className="container">

<div className="columns is-marginless is-centered"><div className="column is-two-thirds has-text-centered"><h2 className="heado">5 week beginners course</h2></div></div>

        <div className="columns is-marginless is-centered">

 <div className="column is-3 has-text-right-desktop">

<h4>Mon 6.30pm:</h4>
<ul className="linklister">
<li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=105195" target="_blank">
25th March MENS REFORMER </a></li>
</ul>


<h4>Mon 9.30am:</h4>

<ul className="linklister">
  <li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94798" target="_blank">
22nd April</a></li>
<li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94800" target="_blank">
10th June</a></li>
<li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94801" target="_blank">
9th September</a></li>
<li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94802" target="_blank">
14th October</a></li>
</ul>


<h4>Wed 6.30pm:</h4>
<ul className="linklister">
  <li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=95895" target="_blank">
3rd April</a></li>
<li><a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=95896" target="_blank">
15th May</a></li>
<li><a href="https://backoffice.bsport.io/customer/payment/offer/19258478?membership=1189" target="_blank">
11th September</a></li>
</ul>


<h4>Wed 7.30pm:</h4>
<ul className="linklister">
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94323" target="_blank">
3rd April</a>
  </li>
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=108635" target="_blank">
20th March MENS REFORMER</a>
  </li>
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94324" target="_blank">
15th May</a>
  </li>
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94324" target="_blank">
11th September</a>
  </li>
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94326" target="_blank">
23rd October</a>
  </li>
</ul>


</div>


<div className="column is-3 is-offset-1-desktop">


<h4>Thu 7.30pm</h4>
<ul className="linklister">
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94428" target="_blank">
4th April</a>
  </li>
<li>
  <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94445" target="_blank">
16th May</a>
</li>

<li>
  <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94446" target="_blank">
12th September</a>
</li>
<li>
  <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=94449" target="_blank">
24th October</a>
</li>
</ul>


<h4>Sat 10.30am</h4>
<ul className="linklister">
  <li>
    <a href="https://backoffice.bsport.io/customer/payment/offer/19314520?membership=1189" target="_blank">
14th September</a>
  </li>
</ul>




<h4>Sat 12.30pm</h4>
<ul className="linklister">
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=107779" target="_blank">
6th April BEGINNERS</a>
  </li>
  <li>
    <a href="https://backoffice.bsport.io/m/Reform%20Pilates%20Cork/1189/workshop/?isPreview=true&activity__in=107783" target="_blank">
11th May BEGINNERS</a>
  </li>
</ul>




</div>


          </div>
      </div>
    </div>
    </Sectionaholder>
  )
}

export default Datebuttons
