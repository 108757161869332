import * as React from "react"
import styled from "styled-components";
import Facebook from '../images/facebook.svg';
import Instagram from '../images/instagram.svg';
import Sveeg from '../images/contact.svg';
import Sveeg_b from '../images/social.svg';
import Shape1 from "../images/shape5.svg";
import Shape2 from "../images/shape2.svg";
import Shape3 from "../images/shape8.svg";
import Shape4 from "../images/shape10.svg";
import Shape5 from "../images/shape1.svg";

const Sectionaholder = styled.div`
.sveeghold{
	background-image: url('${Sveeg}');
}
.sveeghold_b{
	background-image: url('${Sveeg_b}');
}
.shape1{
	background-image: url('${Shape1}');
	margin-top:650px; margin-left:1125px;
}
.shape2{
	background-image: url('${Shape2}');
	margin-top:222px; margin-left:408px;
}
.shape3{
	background-image: url('${Shape3}');
	margin-top:704px; margin-left:1456px;
}
.shape4{
	background-image: url('${Shape4}');
margin-top:700px; margin-left:1580px;
}
.shape5{
	background-image: url('${Shape5}');
margin-top:55px; margin-left:205px;
}
.social-insta{
	margin-left: 0.5rem;
}
.whitelink{
color: #fff;
}

`;

const Contactus = () => {
  return (
  <Sectionaholder>
<section className="section-d" id="contact-reform-pilates-cork">
	<div className="shapeshifter">
<div className="shape1"></div>
<div className="shape2"></div>
<div className="shape3"></div>
<div className="shape4"></div>
<div className="shape5"></div>
</div>	
<div className="containerfullwidth"> 
		<div className="columns is-marginless">

			<div className="column is-1 greypartnopadding"> 
				<div className="sveeghold"></div>
			</div>

			<div className="column is-8 greypart"> 
				<p><strong>Phone:</strong> 087 9380484</p>
				<p><strong>Email:</strong> <a class="whitelink" href="mailto:reformpilatescork@gmail.com">reformpilatescork@gmail.com</a></p>
			</div>
			<div className="column is-3 blackend"> 
					<div className="tophalf socialholder">
	<div className="sveeghold sveeghold_b"></div>
					</div>
					<div className="bottomhalf">
					<a href="https://www.facebook.com/Reform-Pilates-1771217629691784" target="_blank" rel="noreferrer">
					<img src={Facebook} className="" alt="Reform Pilates Cork Facebook"/>
					</a>
					<a href="https://www.instagram.com/reformpilatescork/" target="_blank" rel="noreferrer">
					<img src={Instagram} className="social-insta" alt="Reform Pilates Cork Instagram"/>
					</a>
					</div>
			</div>
		</div>
	</div>
</section>
    </Sectionaholder>
  );
}

export default Contactus