import * as React from "react"
import styled from "styled-components";
import Shape1 from "../images/shape1.svg";
import Shape2 from "../images/shape2.svg";
import Shape3 from "../images/shape3.svg";
import Shape4 from "../images/shape4.svg";
// import Shape5 from "../images/shape5.svg";
// import Shape6 from "../images/shape6.svg";
// import Shape7 from "../images/shape7.svg";
// import Shape8 from "../images/shape8.svg";
// import Shape9 from "../images/shape9.svg";
import Shape10 from "../images/shape10.svg";

const Sectionaholder = styled.div`
.shapes_a{
background-image: url('${Shape2}'); 
margin-top:520px; 
margin-left:900px; 
}
.shapes_b{
	background-image: url('${Shape4}'); margin-top:444px; margin-left:816px; 
}
.shapes_c{
	background-image: url('${Shape10}'); margin-top:880px; margin-left:1820px;
}
.shapes_d{
	background-image: url('${Shape1}'); margin-top:70px; margin-left:158px;
}
.shapes_e{
	background-image: url('${Shape3}'); margin-top:165px; margin-left:615px;
}
`;

const Sectiona = () => {
  return (
  <Sectionaholder>
<section className="section-a">
		<div className="shapeshifter">
<div className="shapes shapes_a"></div>
<div className="shapes shapes_b"></div>
<div className="shapes shapes_c"></div>
<div className="shapes shapes_d"></div>
<div className="shapes shapes_e"></div></div>	
<div className="container "> 
		<div className="columns is-marginless">
		<div className="column is-two-thirds"> 
				<h1 className="anote">Reform Pilates, Cork</h1>
				<h2 className="descriptionzone">Mat, Barre and Reformer pilates classes in Cork City</h2>
			</div>
		</div>
		<div className="columns is-marginless">
			<div className="column is-two-fifths"> 
				<p className="genpee maintext">I'm delighted to welcome you to Reform Pilates. I am a long time devotee of all things pilates. I did my 1st mat class 15 years ago and have been hooked ever since! It's the feeling of strength and control over my body that I fell in love with, and when I found Reformer, that only intensified.<br/><br/>
Now I have achieved a huge ambition by opening this studio, and bringing these amazing disciplines: <strong>Mat</strong>, <strong>Barre</strong> &amp; <strong>Reformer</strong> Pilates, to others. I really believe that done correctly they not only transform bodies, but lives.
I hope to see you all soon in Reform Pilates.</p>
				<p className="signature">Sally Walsh</p>
			</div>
			<div className="column is-two-fifths is-offset-one-fifth"> 
				<div className="picholder">
				<figure className="yellowbox"></figure>
				<figure className="sallybox"></figure>
			</div>
			</div>
		</div>
	</div>
</section>
    </Sectionaholder>
  );
}

export default Sectiona