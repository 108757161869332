import * as React from "react"
import styled from "styled-components";

const Sectionaholder = styled.div`
.newssection{
background: #777;
    margin-bottom: 1em;
    padding: 1em;
	h4, p{
		color: #fff;
	}
	a, a:visited{
		color: #feef00
	}
	a:hover{
		color: #000
	}
	.tag{
		margin-right: 1em;
	}
}
`;

const Threeblocks = () => {
  return (
  <Sectionaholder>
<section className="section-ab">
	<div className="container "> 
		<div className="columns is-marginless">
			<div className="column is-one-third"> 
				<p className="signature">Reformer Pilates</p>
				<p className="genpee maintext">
					The reformer is a piece of equipment which takes core Pilates exercises to another level using weighted springs. it is a challenging total body workout.
				</p>


			</div>
			<div className="column is-one-third"> 
				<p className="signature">Mat Pilates</p>
				<p className="genpee maintext">
					Pilates at its purest, using the strength of the client to perfect the core exercises. With the occasional use of small equipment to keep it interesting!
				</p>
			</div>
			<div className="column is-one-third"> 
				<p className="signature">Barre Pilates</p>

				<p className="genpee maintext">
					Can you imagine ballet, pilates, yoga and intensive toning all done to dance music? that's basically a Barre class!! Amazing toning, cardio and a fun challenge too. try it once and you'll be back.
				</p>
			</div>
		</div>
	</div>
</section>

<section class="newssection">
	  <div class="container">
		<div class="columns is-marginless is-centered">
		<div class="column is-8 has-text-centered">
		<h4>News</h4>
<p><span class="tag">New</span>Sally Walsh speaks to Yay Cork about her brand new studio - <a href="https://www.yaycork.ie/first-look-a-brand-new-specialist-pilates-studio-just-opened-in-cork-city/" target="_blank">click here to read</a></p>		</div>
      </div>
  </div>
</section>

    </Sectionaholder>
  );
}

export default Threeblocks