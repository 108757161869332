import * as React from "react"
import {graphql } from "gatsby"

import Navbar from "../components/navbar"
import Reformfooter from "../components/footer"
import Bookaclass from "../components/bookaclass"
import Sectiona from "../homepage/sectiona"
import Sectionb from "../homepage/sectionb"
import Findus from "../homepage/findus"
import Homeswiper from "../homepage/homeswiper"
import Swipage from "../components/swiper"
import Blogloop from "../components/blogloop"
import Contactus from "../homepage/contactus"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Newtimetable from "../components/newtimetable"
import Bsportpasses from "../components/bsportpasses"
import Datebuttons from "../components/datebuttons"

const MainHome = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Mat, Barre and Reformer pilates classes in Cork City" description="Join instructor Sally Walsh and take part in Barre, Reformer and Mat Pilates Classes in a brand new studio in Cork City."/>

     <Navbar />
     <Swipage />
     <Sectiona />
     <Datebuttons />
     <Sectionb />
          <Newtimetable/>
          <Bsportpasses/>
     <Blogloop getdata={data}/>
      <Bookaclass />
     <Findus />
     <Homeswiper />
     <Contactus />
     <Reformfooter />
    </Layout>
  )
}

export default MainHome


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
                    featuredimage {
              src {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
        }
      }
    }
  }
`

