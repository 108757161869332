import * as React from "react"
import styled from "styled-components"


const Styledsection = styled.section`

`;

// function showSomething(istrue){
//  if(istrue){
//    return <div className="someclass"></div>;
//  } 
//}

const Bookaclass = () => {
  
  //const showpopper = state.vars.showpopper;
  return (
<Styledsection className="section-app" id="bookaclass">
	<div className="container"> 
		<div className="columns is-marginless is-centered">
		
			<div className="column is-two-thirds"> 
				<h3>Find a Class</h3>
			</div>

		</div>
		<div className="columns is-marginless is-centered">

			<div className="column is-two-thirds"> 
				<a className="button popbutton" target="_blank" rel="noreferrer" href="https://reformpilatescork.ie/#bsporttimetable" >Classes</a></div>

		</div>
	</div>
</Styledsection>
  )
}

export default Bookaclass




