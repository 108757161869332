import * as React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
const Styledsection = styled.section`
background: #e5e5e5;
`;

// function showSomething(istrue){
//  if(istrue){
//    return <div className="someclass"></div>;
//  } 
//}

const Newtimetable = () => {
  
  //const showpopper = state.vars.showpopper;
  return (
<Styledsection id="bsporttimetable">
<Helmet>    
<script type='text/javascript' id="bsport-widget-mount">
{`function MountBsportWidget(config, repeat=1) {
            if (repeat > 50) { return }
            if (!window.BsportWidget) {
                return setTimeout(() => {
                    MountBsportWidget(config,repeat+1)
                }, 100 * repeat || 1)
            }
            BsportWidget.mount(config)
        }
`}
    </script>
    <script type='text/javascript'>
        {`setTimeout(function(){ MountBsportWidget({
                "parentElement": "bsport-widget-770269",
                "companyId": 1189,
                "franchiseId": null,
                "dialogMode": 1,
                "widgetType": "calendarV2", 
                "showFab": false,
                "fullScreenPopup": false,
                "styles":undefined,
                "config": {
                    "calendarV2": {                "coaches": [],
                "establishments": [],
                "metaActivities": [],
                "levels": [],
                "variant": null,
                "groupSessionByPeriod": true}
                }  
            })}, 1000);`}
    </script>
</Helmet>

<div class="container">
		<div class="columns is-marginless is-mobile is-centered">
		<div class="column is-10-desktop is-full-mobile">
<div id="bsport-widget-770269"/>
		</div>
      </div>
</div>
</Styledsection>
  )
}

export default Newtimetable
