import * as React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
const Styledsection = styled.section`

`;

// function showSomething(istrue){
//  if(istrue){
//    return <div className="someclass"></div>;
//  } 
//}

const Bsportpasses = () => {
  
  //const showpopper = state.vars.showpopper;
  return (
<Styledsection id="bsportpasses">
<Helmet>    



<script type='text/javascript' id="bsport-widget-mount">{`
        function MountBsportWidget(config, repeat=1) {
            if (repeat > 50) { return }
            if (!window.BsportWidget) {
                return setTimeout(() => {
                    MountBsportWidget(config,repeat+1)
                }, 100 * repeat || 1)
            }
            BsportWidget.mount(config)
        }
    `}</script>
    <script type='text/javascript'>{`
        MountBsportWidget({
                "parentElement": "bsport-widget-214859",
                "companyId": 1189,
                "franchiseId": null,
                "dialogMode": 2,
                "widgetType": "pass", 
                "showFab": false,
                "fullScreenPopup": false,
                "styles":undefined,
                "config": {
                    "pass": {                "paymentPackCategories": [],
                "privatePassCategories": []}
                }  
            })
    `}</script>
</Helmet>

<div><div id="bsport-widget-214859"/></div>
</Styledsection>
  )
}

export default Bsportpasses
