import * as React from "react"
import SwiperCore, { Navigation, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import Slide_a from "../images/yoga-1a.jpg";
import Slide_b from "../images/yoga4.jpg";
import Slide_c from "../images/yoga2.jpg";
import Slide_d from "../images/yoga1.jpg";
import Slide_e from "../images/yoga3.jpg";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);
const SwiperHolder = styled.div`
.slido{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .sliderbloverlay{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity:0.3;
    position: absolute;
  }
}

.hotyogaslide{
  @media screen and (min-width:768px) {
    height: 80vh;  
  }
  @media screen and (max-width:767px) {
    height: 40vh;
  }
  background-size: cover;
  background-position: center;
  position: relative;
  p{
    color: #feef00;
    position: absolute;
    bottom: .5em;
    left: .5em;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 1px 1px 20px #c3bd47;
    font-family: 'Montserrat',sans-serif;
    z-index: 4;
    @media screen and (min-width:768px) {
      font-size: 6rem;
    }
    @media screen and (max-width:767px) {
      font-size: 2.5rem;
    }

  }
}

.sliderbloverlay{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity:0.3;
  position: absolute;
}

.slide_a{
  background-image: url('${Slide_a}');
}
.slide_b{
  background-image: url('${Slide_b}');
}
.slide_c{
  background-image: url('${Slide_c}');
}
.slide_d{
  background-image: url('${Slide_d}');
}
.slide_e{
  background-image: url('${Slide_e}');
}
`;

const Swipage = () => {
  return (
  <SwiperHolder>
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      autoplay={{ delay: 3000 }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
<SwiperSlide><div className="slido hotyogaslide slide_a">
  <div className="sliderbloverlay"></div>
          <p>September <span>Sculpt</span></p>
</div></SwiperSlide>
      <SwiperSlide><div className="slido hotyogaslide slide_b">
          <div className="sliderbloverlay"></div>
          <p>Reformer <span>Classes</span></p>
</div>
</SwiperSlide>
      <SwiperSlide><div className="slido hotyogaslide slide_c">
          <div className="sliderbloverlay"></div>
          <p>Group <span>Classes</span></p>
      </div>
      </SwiperSlide>
      <SwiperSlide><div className="slido hotyogaslide slide_d">
          <div className="sliderbloverlay"></div>
          <p>One-to-One <span>Classes</span></p>
      </div></SwiperSlide>
      <SwiperSlide><div className="slido hotyogaslide slide_e">
          <div className="sliderbloverlay"></div>
          <p>Barre <span>Classes</span></p>
      </div></SwiperSlide>
    </Swiper>
    </SwiperHolder>
  );
}

export default Swipage